import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Industry() {
    const [toggleSidebar, setToggleSidebar] = useState(true);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [id, setId] = useState(0);
    const [number, setNumber]= useState([]);
    const [page, setPage] = useState(1);
    const [industries, setIndustries] = useState([]);
    const getIndustries = () => {
        axios.get(`https://api.vtalentsolution.com/api/getIndustriesPage?page=`+page+``)
        .then((res) => {
            setIndustries(res.data.data);
            const old = [];
            for (let i = 1; i <= res.data.last_page; i++) {
                old.push(i);
            }
            setNumber(old);
        });
    }
    const switchIndustry = (i) => {
        Swal.fire({
            icon:'question',
            text: "Bạn muốn đổi tình trạng ngành nghề?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Có",
            denyButtonText: `Không`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://api.vtalentsolution.com/api/switchIndustry?id=`+i+``)
                .then((res)=>{
                    if(res.data.check === true){
                        Toast.fire({
                            icon: "success",
                            title: "Đã đổi thành công",
                        }).then(()=>{
                            getIndustries();
                            getIndustriesSearch();
                        })
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    }
                })
            } else if (result.isDenied) {
            }
        })
    }
    const [industryName, setIndustryName] = useState('');
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const createIndustry = () => {
        setShow1(true);
    }
    const submitCreateIndustry = () => {
        if(industryName === ''){
            Toast.fire({
                icon: "warning",
                title: "Nhập tên ngành nghề",
            });
        } else {
            axios.post(`https://api.vtalentsolution.com/api/createIndustry?industry_name=`+industryName+``)
            .then((res) =>{
                if(res.data.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Đã thêm thành công",
                    }).then(() => {
                        setShow1(false);
                        getIndustries();
                        getIndustriesSearch();
                    });
                } else if (res.data.msg.industry_name) {
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.industry_name,
                    });
                }
            })
        }
    }
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const editIndustryName = (i, n) => {
        setShow2(true);
        setId(i);
        setIndustryName(n);
    }
    const submitEditIndustryName = async () => {
        const data = new URLSearchParams();
        data.append('id', id);
        data.append('industry_name', industryName);
        await axios({
            method: "post",
            url: "https://api.vtalentsolution.com/api/editIndustry",
            data: data,
        }).then((res)=>{
            const result = res.data;
            if(result.check === true){
                Toast.fire({
                    icon: "success",
                    title: "Đã sửa thành công",
                }).then(() => {
                    setShow2(false);
                    getIndustries();
                    getIndustriesSearch();
                });
            } else if (result.msg.id) {
                Toast.fire({
                    icon: "error",
                    title: result.msg.id,
                });
            } else if (result.msg.industry_name) {
                Toast.fire({
                    icon: "error",
                    title: result.msg.industry_name,
                });
            }
        })
    }
    const delIndustry = async (i) => {
        Swal.fire({
            title: "Bạn có muốn xóa ngành nghề này?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Có",
            denyButtonText: `Không xóa`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("id", i);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/deleteIndustry",
                    data: data,
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Đã xóa thành công",
                        }).then(() => {
                            getIndustries();
                            getIndustriesSearch();
                        });
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    } else if (result.msg) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    }
    const [industriesSearch, setIndustriesSearch] = useState([]);
    const getIndustriesSearch = (e) => { 
        axios.get(`https://api.vtalentsolution.com/api/getIndustriesSearch?search=`+e+``)
        .then((res)=>{
            setIndustriesSearch(res.data);
        })
    }
    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
        }else{
            window.location.replace('/');
        }  
    }, []);
    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            axios.get(`https://api.vtalentsolution.com/api/getIndustriesPage?page=`+page+``)
            .then((res) => {
                setIndustries(res.data.data);
                const old = [];
                for (let i = 1; i <= res.data.last_page; i++) {
                    old.push(i);
                }
                setNumber(old);
            });
        }else{
            window.location.replace('/');
        }  
    }, [page]);
    return (
    <>
        <div id="page-top" className={toggleSidebar ? "" : "sidebar-toggled"}>
            <div id="wrapper">
                <Sidebar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* <!-- Main Content --> */}
                    <div id="content">
                        <Navbar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                        <div className="container-fluid">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                        <h5 className="m-0 font-weight-bold text-primary">Danh sách ngành nghề</h5>
                                        <a className="d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-2 p-2" onClick={createIndustry}>
                                            <i className="fas fa-upload fa-sm text-white-50 mr-2"></i> 
                                            Thêm
                                        </a>
                                    </div>
                                    <div className="row mb-4">
                                        {/* <h5 className="m-0 font-weight-bold text-primary">Tìm kiếm</h5> */}
                                        <div className="col-lg-6 col-12">
                                            <input type="text" className="form-control border small" placeholder="Search for..." 
                                            aria-label="Search" aria-describedby="basic-addon2" onKeyUp={(e)=>getIndustriesSearch(e.target.value)}/>
                                        </div>
                                        {
                                            industriesSearch.length>0 &&
                                            <div className="col-12 mt-4">
                                                <h6>Kết quả tìm kiếm: {industriesSearch.length}</h6>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="card-body">
                                {
                                    industries && industries.length > 0 && industriesSearch.length <= 0 &&
                                    <div className="table-responsive">
                                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr className='table-secondary'>
                                                    <th>Tên</th>
                                                    <th>Tình trạng</th>
                                                    {/* <th>Ngày tạo</th> */}
                                                    <th>Tùy chỉnh</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                industries.map((item, index)=>(
                                                <tr key={index} className="">
                                                    <td className='align-middle'>{item.industry}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchIndustry(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    {/* <td className='align-middle'>{item.created_at}</td> */}
                                                    <td className='text-center align-middle'>
                                                        <span className='me-2 my-1 fs-4'><i className="fa-solid fa-pen-to-square" onClick={()=>editIndustryName(item.id, item.industry)}  style={{ cursor:"pointer", color: "grey" }}></i></span>
                                                        <span className='my-1 fs-4'><i className="fa-solid fa-trash" onClick={()=>delIndustry(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                {
                                    industriesSearch.length > 0 &&
                                    <div className="table-responsive">
                                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr className='table-secondary'>
                                                    <th>Tên</th>
                                                    <th>Tình trạng</th>
                                                    {/* <th>Ngày tạo</th> */}
                                                    <th>Tùy chỉnh</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                industriesSearch.map((item, index)=>(
                                                <tr key={index} className="">
                                                    <td className='align-middle'>{item.industry}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchIndustry(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    {/* <td className='align-middle'>{item.created_at}</td> */}
                                                    <td className='text-center align-middle'>
                                                        <span className='me-2 my-1 fs-4'><i className="fa-solid fa-pen-to-square" onClick={()=>editIndustryName(item.id, item.industry)}  style={{ cursor:"pointer", color: "grey" }}></i></span>
                                                        <span className='my-1 fs-4'><i className="fa-solid fa-trash" onClick={()=>delIndustry(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </div>
                                {
                                    industriesSearch.length <= 0 &&
                                    <div className="d-flex">
                                        <nav aria-label="Page navigation example" style={{ margin: 'auto' }}>
                                            <ul className="pagination">
                                            {
                                                number && number.length>0 && number.map((item,index)=>(
                                                    page == item ?
                                                        <li className="page-item mx-1 active" key={index} style={{ cursor: "pointer" }} onClick={()=>setPage(item)}>
                                                            <span className="page-link">{item}</span>
                                                        </li>
                                                    :
                                                        <li className="page-item mx-1" key={index} style={{ cursor: "pointer" }} onClick={()=>setPage(item)}>
                                                            <span className="page-link">{item}</span>
                                                        </li>
                                                ))
                                            }
                                            </ul>
                                        </nav>
                                    </div> 
                                }
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
        {/* Modal Create Industry */}
        <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
                <Modal.Title>Ngành nghề mới</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    type="text"
                    id="username"
                    className="form-control"
                    placeholder="Ngành nghề"
                    onChange={(e) => setIndustryName(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose1}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={submitCreateIndustry}>
                    Lưu
                </Button>
            </Modal.Footer>
        </Modal>

        {/* Modal Edit Industry */}
        <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
                <Modal.Title>Update Tên Loại Tài Khoản</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    value={industryName}
                    type="text"
                    className="form-control"
                    onChange={(e) => setIndustryName(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose2}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={submitEditIndustryName}>
                    Lưu
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default Industry
