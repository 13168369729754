import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FileUploader } from "react-drag-drop-files";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function History() {
    const [toggleSidebar, setToggleSidebar] = useState(true);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [locationList, setLocationList] = useState([]);
    const getLocationList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getLocationsOn`)
        .then((res)=> {
            setLocationList(res.data);
        })
    }
    const [industryList, setIndustryList] = useState([]);
    const getIndustryList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getIndustriesOn`)
        .then((res)=> {
            setIndustryList(res.data)
        })
    }
    const [listHistory, setListHistory]= useState([]);
    const getListHistory = async () => {
        await axios.get('https://api.vtalentsolution.com/api/getAllJobCv')
        .then((res)=>{
            setListHistory(res.data);
        })
    }
    const [jobName, setJobName] = useState('');
    const [locationId, setLocationId] = useState(0);
    const [industryId, setIndustryId] = useState(0);
    const [fullName, setFullName] = useState('');
    const [historySearch, setHistorySearch] = useState([]);
    const getSearch = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getHistory?jobName=`+jobName+`&fullName=`+fullName+`&locationId=`+locationId+`&industryId=`+industryId+``)
        .then((res)=>{
            setHistorySearch(res.data);
        })
    }
    useEffect(()=> {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getListHistory();
            getLocationList();
            getIndustryList();
        }else{
            window.location.replace('/');
        }  
    },[]);
    return (
    <>
        <div id="page-top" className={toggleSidebar ? "" : "sidebar-toggled"}>
            <div id="wrapper">
                <Sidebar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* <!-- Main Content --> */}
                    <div id="content">
                        <Navbar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                        <div className="container-fluid">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                        <h5 className="m-0 font-weight-bold text-primary">Lịch sử xử lý hồ sơ</h5>
                                        {/* <a href="#" className="d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-2 p-2" onClick={createDoc}>
                                            <i className="fas fa-upload fa-sm text-white-50"></i> 
                                            Thêm
                                        </a> */}
                                    </div>
                                    <div className="row">
                                        <div className='col-lg-12 border rounded bg-light'>
                                            <h3 className='text-left py-3'>Tìm kiếm</h3>
                                            <div className="row">
                                                <div className='col-md-6 col-sm-12'>
                                                    <div className="mb-3">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control form-control" placeholder="Tên công việc" onChange={(e)=>setJobName(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 col-sm-12'>
                                                    <div className="mb-3">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control form-control" placeholder="Tên Ứng Viên" onChange={(e)=>setFullName(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 col-sm-12'>
                                                    <div className="mb-3">
                                                        <div className="input-group">
                                                            <select className='w-100 form-control border' onChange={(e)=>setLocationId(e.target.value)}>
                                                                <option value="0" >Select location</option>
                                                            {
                                                                locationList && locationList.length > 0 && locationList.map((item, index)=>(
                                                                    <option key={index} value={item.id}>{item.location}</option>
                                                                ))
                                                            }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 col-sm-12'>
                                                    <div className="mb-3">
                                                        <div className="input-group">
                                                            <select className='w-100 form-control border' onChange={(e)=>setIndustryId(e.target.value)}>
                                                                <option value="0">Industry</option>
                                                            {
                                                                industryList && industryList.length > 0 && industryList.map((item, index)=>(
                                                                    <option key={index} value={item.id}>{item.industry}</option>
                                                                ))
                                                            }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                {/* <div className='col-12'>
                                                {
                                                    jobsSearch.length>0 &&
                                                    <div className="col-12 mt-4">
                                                        <h6>Kết quả tìm kiếm: {jobsSearch.length}</h6>
                                                    </div>
                                                }
                                                </div> */}
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className='col-lg-2 col-md-6 col-sm-12'>
                                                    <div className="mb-3">
                                                        <div className="input-group ">
                                                            {/* <a className="btn btn-primary btn-lg py-2 px-4 ml-auto d-lg-block" href="">Search</a> */}
                                                            <button className="btn btn-primary ml-auto w-100 d-lg-block" onClick={()=>getSearch()}><i className="fa-solid fa-magnifying-glass"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>           
                                    </div>
                                </div>
                                <div className="card-body">
                                {
                                    listHistory && listHistory.length >= 0 && historySearch.length <= 0 &&
                                    <div className="table-responsive">
                                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr className='table-dark text-center'>
                                                    <th colSpan={4}>Công Việc</th>
                                                    <th colSpan={4}>CV</th>
                                                </tr>
                                                <tr className='table-secondary text-center'>
                                                    <th>Công việc</th>
                                                    <th>Lương</th>
                                                    <th>Ngành nghề</th>
                                                    <th>Địa điểm</th>
                                                    <th>Tên Ứng Viên</th>
                                                    <th>Email</th>
                                                    {/* <th>Phone</th> */}
                                                    <th>File</th>
                                                    <th>New File</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                listHistory.map((item, index)=>(
                                                <tr key={index} className="text-center">
                                                    <td className='align-middle'>{item.JobName}</td>
                                                    <td className='align-middle'>{item.Salary}</td>
                                                    <td className='align-middle'>{item.industryName}</td>
                                                    <td className='align-middle'>{item.location}</td>
                                                    <td className='align-middle'>{item.fullName}</td>
                                                    <td className='align-middle'>{item.email}</td>
                                                    {/* <td className='align-middle'>{item.phone}</td> */}
                                                    <td className='align-middle'>
                                                        <a href={`https://api.vtalentsolution.com/FileCV/`+item.fileCv+``} target="_blank" className="text-decoration-none">{item.fileCv}</a>
                                                    </td>
                                                    <td className='align-middle'>
                                                        <a href={`https://api.vtalentsolution.com/FileCV/`+item.fileCv+``} target="_blank" download={item.fileCv} className="text-decoration-none">{item.fileCv}</a>
                                                    </td>
                                                    
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                {
                                    historySearch.length > 0 &&
                                    <div className="table-responsive">
                                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr className='table-dark text-center'>
                                                    <th colSpan={4}>Công Việc</th>
                                                    <th colSpan={4}>CV</th>
                                                </tr>
                                                <tr className='table-secondary text-center'>
                                                    <th>Công việc</th>
                                                    <th>Lương</th>
                                                    <th>Ngành nghề</th>
                                                    <th>Địa điểm</th>
                                                    <th>Tên Ứng Viên</th>
                                                    <th>Email</th>
                                                    {/* <th>Phone</th> */}
                                                    <th>File</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                historySearch.map((item, index)=>(
                                                <tr key={index} className="text-center">
                                                    <td className='align-middle'>{item.JobName}</td>
                                                    <td className='align-middle'>{item.Salary}</td>
                                                    <td className='align-middle'>{item.industryName}</td>
                                                    <td className='align-middle'>{item.location}</td>
                                                    <td className='align-middle'>{item.fullName}</td>
                                                    <td className='align-middle'>{item.email}</td>
                                                    {/* <td className='align-middle'>{item.phone}</td> */}
                                                    <td className='align-middle'>
                                                        <a href={"../FileCv/"+item.fileCv} download={item.fileCv} className="text-decoration-none">{item.fileCv}</a>
                                                    </td>
                                                    
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    </>
    )
}

export default History
